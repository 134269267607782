import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeNL from './pages/nl/home';
import HomeEN from './pages/en/home';
import NoPageFound from "./pages/404";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeNL />} />
        <Route exact path='/en' element={<HomeEN />} />
        <Route path='*' element={<NoPageFound />} />
      </Routes>
    </Router>
  );
}

export default App;
