import '../home.css';
import logo from '../../img/dynative-logo.png';
import deepblue from '../../img/logos/deepblue-logo.svg';
import vt from '../../img/logos/vt-logo.png';
import gamefi from '../../img/logos/gamefi-logo.svg';
import mix from '../../img/logos/mix-logo.svg';
import ovo from '../../img/logos/ovo-logo.webp';
import baanx from '../../img/baanx-logo.png'
import { useNavigate } from "react-router-dom";

function HomeNL() {
const navigate = useNavigate();
document.title = "Dynative - Toonaangevend digitaal bureau"
document.documentElement.lang = "nl";
  return (
    <div className="App">
      <body>
        <nav>
        	<div class='container nav-cont'>
                <a class='logo-wrapper' href='#start'>
                    <img class='logo' src={logo} alt="Dynative Logo" />
                </a>
                <div class='nav-links'>
                    <a class='nav-link' href='#start'>Home</a>
                    <a class='nav-link' href='#work'>Projecten</a>
                    <a class='nav-link-2' href='#contact'>Contact</a>
                    <div class='nav-button-wrapper' onClick={() => navigate("/en")}><button class='nav-button' data-umami-event="{Change Language to EN}">English</button></div>
                 </div>
              </div>
        </nav>
          <section id='start' class="intro-section">
              <div class="container">
                  <h2>Dynative — Toonaangevend Webflow ontwerp- en ontwikkelingsbureau</h2>
                  <h1>Wij maken razendsnelle Webflow websites die startups en scale-ups helpen groeien.</h1>
                  <h2>We helpen start-ups en scale-ups om een relatie met hun klanten op te bouwen, door het ontwikkelen van duidelijke en gedenkwaardige klantervaringen.
                  </h2>
                  <a href='#contact' class='button'><button data-umami-event="{CTA-nl}">Neem contact op</button></a>
              </div>
          </section>
          <section id='companies'>
              <div class="container">
                <h2 class="work-heading" id='work'>We hebben eerder samengewerkt met</h2>
                <div class="companies-grid">
                    <div class="companies-wrapper">
                        <img class='companies' src={deepblue}  alt='Deepblue Security' />
                    </div>
                    <div class="companies-wrapper">
                        <img class='companies' src={vt}  alt='Hecht/GGD Hollands Midden' />
                    </div>
                    <div class="companies-wrapper">
                        <img class='companies' src={ovo}  alt='OVO lasik + lens' />
                    </div>
                    <div class="companies-wrapper">
                        <img class='companies' src={baanx}  alt='Baanx' />
                    </div>
                    <div class="companies-wrapper">
                        <img class='companies' src={gamefi}  alt='Gamefi' />
                    </div>
                    <div class="companies-wrapper">
                        <img class='companies' src={mix}  alt='Mix' />
                    </div>
                </div>
              </div>
          </section>
          <section class="work">
              <div class="container">
                  <h2 class="work-heading" id='work'>Projecten</h2>
                  <div class="work-grid">
                      <a class="deepblue" data-umami-event="{deepblue-nl}" href="https://www.deepbluesecurity.nl/" target="_blank" rel="noopener noreferrer">
                          <div class="project-details">
                              <h3 class="work-heading">DeepBlue Security</h3>
                              <p class="subtitle">Cybersecurity bedrijf dat zich specialiseert in alles van pentesten tot incident response.</p>
                          </div>
                      </a>
                    <a class="dakxl" data-umami-event="{dakxl-nl}" href="https://www.dakxl.nl/" target="_blank" rel="noopener noreferrer">
                          <div class="project-details">
                              <h3 class="work-heading">DakXL</h3>
                              <p class="subtitle">Een dakwerkbedrijf dat gespecialiseerd is in alles, van dakonderhoud tot duurzame innovaties, met een verhaal dat wordt gekenmerkt door groei, ambitie en toewijding aan kwaliteit.</p>
                          </div>
                      </a>
                      <a class="gamefi" data-umami-event="{gamefi-nl}" href="https://www.game-fi.io/" target="_blank" rel="noopener noreferrer">
                          <div class="project-details">
                              <h3 class="work-heading">Gamefi</h3>
                              <p class="subtitle">Een onafhankelijke Blockchain-game-ontwikkelingsstudio met een passie voor het creëren van financiële gamingproducten en economieën die eigendom zijn van spelers.</p>
                          </div>
                      </a>
                      <div class="other-projects">
                          <h3 class="work-heading">Overige Projecten</h3>
                          <a class="other-project" data-umami-event="{duranross-nl}" href="https://www.duranross.com/" target="_blank" rel="noopener noreferrer">
                              <h3 class="work-heading">Duran Ross</h3>
                              <p class="subtitle">Duran Ross is een ervaren filmmaker en editor.</p>
                          </a>
                          <a class="other-project" data-umami-event="{dreampurpose-nl}" href="https://www.dreampurpose.com/" target="_blank"
                              rel="noopener noreferrer">
                              <h3 class="work-heading">Dreampurpose</h3>
                              <p class="subtitle">Dream Purpose heeft als doel kleding te creëren die je inspireert om kansen te grijpen, je individualiteit te vieren en je dromen waar te maken.</p>
                          </a>
                          <a class="other-project" data-umami-event="{mix-nl}" href="https://www.workwithmix.com/" target="_blank"
                              rel="noopener noreferrer">
                              <h3 class="work-heading">Mix</h3>
                              <p class="subtitle">Mix is een creatief bureau gevestigd in Amsterdam.</p>
                          </a>
                          <a class="other-project" data-umami-event="{intonation-nl}" href="https://www.intonationventures.com/" target="_blank"
                              rel="noopener noreferrer">
                              <h3 class="work-heading">Intonation ventures</h3>
                              <p class="subtitle">Gaming-investeringsmaatschappij gevestigd in de Verenigde Staten</p>
                          </a>
                      </div>
                  </div>
              </div>
          </section>
          <section class="contact" id="contact">
              <div class="container">
                  <h2 class="work-heading">Voor opdrachten, freelance projecten en vragen</h2>
                  <div class="horizontal-div">
                      <a class="contact-link" data-umami-event="{mail-nl}" href="mailto:hello@dynative.co">hello@dynative.co</a>
                      <a class="contact-link" data-umami-event="{whatsapp-nl}" href="https://wa.me/+31622416373">
                        <svg  class="img" width="21" height="21" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19.049,4.907A9.91,9.91,0,0,0,3.456,16.864L2.049,22,7.3,20.621a9.91,9.91,0,0,0,4.734,1.208h0A9.912,9.912,0,0,0,19.048,4.908Zm-7.008,15.25a8.221,8.221,0,0,1-4.195-1.15l-.3-.18-3.118.817.833-3.039-.195-.312a8.239,8.239,0,1,1,15.215-4.38,8.255,8.255,0,0,1-8.24,8.243Zm4.516-6.172c-.246-.125-1.465-.722-1.692-.8s-.391-.125-.559.125-.64.8-.785.973-.288.187-.535.062a6.8,6.8,0,0,1-1.992-1.23A7.547,7.547,0,0,1,9.616,11.4c-.145-.246-.016-.382.11-.5s.246-.289.371-.434a1.645,1.645,0,0,0,.247-.414.458.458,0,0,0-.02-.434c-.063-.125-.559-1.344-.762-1.84S9.156,7.352,9,7.344s-.31-.008-.473-.008a.917.917,0,0,0-.66.309A2.78,2.78,0,0,0,7,9.712a4.819,4.819,0,0,0,1.011,2.562,11.046,11.046,0,0,0,4.23,3.739c.59.254,1.051.406,1.41.524a3.421,3.421,0,0,0,1.559.1,2.548,2.548,0,0,0,1.672-1.176,2.061,2.061,0,0,0,.145-1.176c-.058-.111-.222-.171-.472-.3Z" fill-rule="evenodd"/>
                        </svg>
                        +31 6 22416373
                      </a>
                      <a class="contact-link" data-umami-event="{linkedin-nl}" href='https://www.linkedin.com/company/dynative-agency/'>
                        <svg width="21" height="21" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M19.039,19.043H16.078V14.4c0-1.106-.023-2.53-1.544-2.53-1.544,0-1.78,1.2-1.78,2.449v4.722H9.792V9.5h2.845v1.3h.039a3.12,3.12,0,0,1,2.808-1.542c3,0,3.556,1.975,3.556,4.546v5.238ZM6.447,8.194A1.72,1.72,0,1,1,8.168,6.473,1.719,1.719,0,0,1,6.447,8.194ZM7.932,19.043H4.963V9.5H7.932ZM20.521,2H3.476A1.458,1.458,0,0,0,2,3.441V20.559A1.458,1.458,0,0,0,3.476,22H20.518A1.463,1.463,0,0,0,22,20.559V3.441A1.464,1.464,0,0,0,20.518,2Z"/>
                        </svg>
                      </a>
                      <a class="contact-link" data-umami-event="{instagram-nl}" href='https://www.instagram.com/dynativeco/'>
                        <svg width="21" height="21" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M21.94,7.88a7.59,7.59,0,0,0-.46-2.43,4.85,4.85,0,0,0-1.16-1.77,4.85,4.85,0,0,0-1.77-1.16,7.59,7.59,0,0,0-2.43-.46C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.59,7.59,0,0,0-2.43.46A4.85,4.85,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.85,4.85,0,0,0,1.77,1.16,7.59,7.59,0,0,0,2.43.46C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.59,7.59,0,0,0,2.43-.46,5.19,5.19,0,0,0,2.93-2.93,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.33,3.33,0,0,1-1.9,1.9,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.61,5.61,0,0,1,6.1,19.8a3.33,3.33,0,0,1-1.9-1.9A5.61,5.61,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.61,5.61,0,0,1,4.2,6.1,3.33,3.33,0,0,1,6.1,4.2,5.61,5.61,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34,3.33,3.33,0,0,1,1.9,1.9A5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16Z"/><path d="M12,6.86A5.14,5.14,0,1,0,17.14,12,5.14,5.14,0,0,0,12,6.86Zm0,8.47A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"/>
                            <path d="M17.34,5.46a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Z"/>
                        </svg>
                      </a>
                  </div>
              </div>
          </section>
          <section class="legal" id="legal">
              <div class="container">
                  <div class="horizontal-between">
                      <p class="subtitle">© 2020-2024 Dynative. Alle rechten voorbehouden.</p>
                      <p class="subtitle">KVK:81545037 | BTW:NL003574782B92</p>
                  </div>
              </div>
          </section>
      </body>
    </div>
  );
}

export default HomeNL;
