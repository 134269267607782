import './home.css';
import { useNavigate } from "react-router-dom";

function NoPageFound() {
const navigate = useNavigate();
document.title = "Dynative | 404"
  return (
    <div className="App">
      <body class='nofoundwrapper'>
        <h1>404 - No page found</h1>
        <button onClick={() => navigate("/")}>Back to home</button>
      </body>
    </div>
  );
}

export default NoPageFound;